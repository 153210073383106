import React from 'react'

function About() {
    return (
        <>
      <section id="about-section" className="full-width">
        <a id="about" />
      </section>
      <section id="about2-section" className="full-width">
        <a id="about" />
      </section>
      <section id="about3-section" className="full-width">
        <a id="about" />
      </section>
      
            </>
    )
}

export default About