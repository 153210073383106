import React from "react";
import { useWeb3Modal } from "@web3modal/react";
import { getAccount } from "@wagmi/core";
import { Box } from "@mui/material";
export default function Header() {
  const { open } = useWeb3Modal();
  const { address } = getAccount();
  return (
    <header id="main-header">
      <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
        <div className="container">
          <a id="main-logo" className="navbar-brand" href="#">
            <span className="colored">/</span>OPENEX
            <span className="colored">\</span>
          </a>

          <button
            id="hamburger"
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          {/* Navigation Links */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="#cta">
                  Join Presale!
                </a>
                </li>
                <li className="nav-item">
                <a className="nav-link" href="https://openex-protocol.gitbook.io/docs/"target="_blank">
                  Docs &#9889;
                </a>
              </li>
              <li className="nav-item">
                <Box sx={{
                  color: "#000000",
                  background:
                    "#ffffff",
                  fontSize: "15px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat",
                  borderRadius: "25px",
                  "&.Mui-disabled": {
                    color: "#979EA7",
                  },
                  "&:hover": {
                    background:
                      "#E5BF3D",
                  },
                  px: 1,
                  py: 0.5,
                  mt: 0.5,
                  cursor: "pointer"
                }}
                  onClick={async () => {
                    await open();
                  }}
                >
                  {address
                    ? address.slice(0, 4) + "..." + address.slice(-4)
                    : "Connect Wallet"}
                </Box>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

  );
}
