import React from 'react'

function DLS() {
    return (
        <section id="dlc">
            <div className="container">
                <div className="row text-center small-margin">
                    <div className="col-md-6">
                        <div className="dlc-card">
                            <img src="images/advantage.jpg" className="img-fluid" />
                            <h2 className="short-hr-center">Openex Advantages</h2>
                            <div className="dlc-features">
                                <p>
                                We are building #1 perp-dex on opBNB. We have the first mover advantage. 
                                All openex products are built around the $OPX token. With the launch of 
                                our product in 2 weeks, we'll have the momentum and the boost for $OPX.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="dlc-card">
                            <img src="images/dlc.jpg" className="img-fluid" />
                            <h2 className="short-hr-center">Together Strong</h2>
                            <div className="dlc-features">
                                <p>
                                    As Openex team, we are committed to develop 1st grade products on opBNB.
                                    But we are only as strong as our community. Join our community. 
                                    Apes together strong.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-md-6">
                        <div id="newsletter-box">
                            <h3 className="short-hr-center">Newsletter</h3>
                            <form id="newsletter" data-toggle="validator">
                                <input
                                    type="email"
                                    id="emailsign"
                                    placeholder="Your email address"
                                />{" "}
                                {/* Email Field */}
                                <button type="submit" id="form-signup" className="button">
                                    SUBSCRIBE
                                </button>
                                <div id="msgSignup" className="h3 text-center hidden" />
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <h3 className="short-hr-center">Join Our Community</h3>
                        <ul className="social-links">
                            {" "}
                            {/* Social Media Icons */}
                            <li>
                                <a href="https://t.me/openexprotocol">
                                    <i className="fab fa-telegram-plane fa-3x icon-social" />
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Openexprotocol">
                                    <i className="fab fa-twitter-square  fa-3x icon-social" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default DLS