import React from "react";
import { Box, Container } from "@mui/material";
import { StyledText } from "../components/SmallComponents/AppComponents";

function HeroSection() {
  return (
    <section id="hero-section">
      <div className="row hero-unit">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <div className="hero-caption">
                {/* Main Tagline */}
                <h1></h1>
                <h3 className="colored">
                </h3>
                {/* Image headline 
                      <img src="images/headline.png" class="img-fluid" alt="headline">/* 
                      */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default HeroSection;
