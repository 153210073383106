import React from 'react'

function Footer() {
    return (
        <div id="footer">
            <div className="container">
                <div className="row text-center">
                    <div className="col-md-12">
                        <div className="row brands">
                            <div className="col-md-4">
                                <a href="#">
                                    <img src="images/brand1.png" className="img-fluid" />
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="#">
                                    <img src="images/brand2.png" className="img-fluid" />
                                </a>
                            </div>
                            <div className="col-md-4">
                                <a href="#">
                                    <img src="images/brand3.png" className="img-fluid" />
                                </a>
                            </div>
                        </div>
                        { /* <ul>
                            <li>
                                <a href="privacy_policy.html"></a>
                            </li>
                            <li>
                                <a href="terms_and_conditions.html"></a>
                            </li>
                        </ul>*/}
                    </div>
                </div>
                <div className="row text-center">
                    <div className="col-md-12">
                        <p id="copyright">
                            © OPENEX <span id="year"> </span> - #1 PERP-DEX by{" "}
                            <a href="#">Openex Team</a>
                        </p>
                        {/* Copyright Text */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer