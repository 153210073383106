export const opBNB = {
  id: 204,
  name: "opBNB Mainnet",
  network: "opBNB Mainnet",
  nativeCurrency: {
    decimals: 18,
    name: "opBNB Mainnet",
    symbol: "BNB",
  },
  rpcUrls: {
    public: { http: ["https://opbnb-mainnet-rpc.bnbchain.org"] },
    default: { http: ["https://opbnb-mainnet-rpc.bnbchain.org"] },
  },
  blockExplorers: {
    etherscan: { name: "opBNB", url: "http://mainnet.opbnbscan.com" },
    default: { name: "opBNB", url: "http://mainnet.opbnbscan.com" },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 1473583,
    },
  },
};
