import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import About from "./pages/About";
import Media from "./pages/Media&CTA";
import DLS from "./pages/DLS";
import Footer from "./components/Footer";

function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 204) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header />
      <HeroSection />
      <About />
      <Media />
      <DLS />
      <Footer />
    </>
  );
}

export default App;
