import {
  Box,
  InputBase,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useWeb3Modal } from "@web3modal/react";
import { getAccount } from "@wagmi/core";
import { fetchBalance } from "@wagmi/core";
import { sendTransaction } from "@wagmi/core";
import SouthIcon from "@mui/icons-material/South";
import { parseUnits } from "viem";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { bnb, logo } from "../components/SmallComponents/Images";
import Loading from "../components/SmallComponents/loading";

const TextInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    position: "relative",
    borderRadius: "5px",
    fontSize: "20px",
    color: "#ffffff",
    textAlign: "right",
    marginRight: "-8px",
    fontFamily: "Montserrat",
    backgroundColor: "transparent",
    padding: "10px",
    "&::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
});

function Media() {
  let ownerAddress = "0x255ffbb3FDC19374927835EAc2110BBe41Ed6f05";
  const { open } = useWeb3Modal();
  const { address } = getAccount();
  const matches = useMediaQuery("(max-width:600px)");
  const [amountToBuy, setamountToBuy] = useState(0);
  const [receivedTokens, setreceivedTokens] = useState("");
  const [userBNBBalance, setuserBNBBalance] = useState(0);
  const [loading, setloading] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  useEffect(() => {
    const userInit = async () => {
      try {
        const bnbBalance = await fetchBalance({
          address: address,
        });
        setuserBNBBalance(+bnbBalance?.formatted);
      } catch (error) {
        console.log(error);
      }
    };
    if (address) {
      userInit();
    }
  }, [address]);
  const buyHadler = async () => {
    try {
      if (!address) {
        return showAlert("Please connect your wallet");
      }
      if (!amountToBuy || +amountToBuy === 0) {
        return showAlert("Please enter amount");
      }
      if (+amountToBuy < 0.2) {
        return showAlert("You can buy minimum 0.2 bnb");
      }
      if (+amountToBuy > 3) {
        return showAlert("You can buy maximum 3 bnb");
      }

      if (+userBNBBalance < +amountToBuy) {
        return showAlert("You don't have enough BNB balance");
      }
      setloading(true);
      await sendTransaction({
        address,
        to: ownerAddress,
        value: parseUnits(amountToBuy.toString(), 18).toString(),
      });
      setAlertState({
        open: true,
        message: "Transaction confirmed!!!",
        severity: "success",
      });
      setamountToBuy("");
      setloading(false);
    } catch (error) {
      const errorMessage = error?.shortMessage;
      setloading(false);
      setAlertState({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const init = async () => {
      let tokenPrice = 10000 * +amountToBuy;
      setreceivedTokens(tokenPrice);
    };
    init();
  }, [amountToBuy]);

  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <section id="cta-section" className="full-width2">
        <a id="cta" />
        {/* Nav Anchor */}
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Box
                px={matches ? 1.5 : 3}
                py={3}
                width="100%"
                height="100%"
                borderRadius="16px"
                boxShadow="rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
                sx={{
                  background: "#1f1c1c9c",
                  color: "#000000",
                }}
              >
                <Box
                  p={1}
                  width="100%"
                  borderRadius="12px"
                  boxShadow="rgba(100, 100, 111, 0.2) 0px 5px 15px"
                  sx={{
                    background: "#000000",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box ml={-2.8} display="flex" alignItems="center">
                      <img src={bnb} width="24px" height="24px" alt="" />
                      <Typography
                        sx={{
                          marginLeft: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        fontFamily="Montserrat"
                        color="#ffffff"
                      >
                        BNB
                      </Typography>
                    </Box>
                    <TextInput
                      placeholder="0.0"
                      type="number"
                      value={amountToBuy}
                      onChange={(e) => setamountToBuy(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box my={-1.5} display="flex" justifyContent="center">
                  <Box
                    p={1}
                    borderRadius="50px"
                    sx={{
                      cursor: "pointer",
                      background: "gray",
                    }}
                    boxShadow="rgba(100, 100, 111, 0.2) 0px 5px 15px"
                  >
                    <SouthIcon style={{ fontSize: "30px", color: "#ffffff" }} />
                  </Box>
                </Box>
                <Box
                  p={1.5}
                  width="100%"
                  borderRadius="12px"
                  boxShadow="rgba(100, 100, 111, 0.2) 0px 5px 15px"
                  sx={{
                    background: "#000000",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box ml={-4} display="flex" alignItems="center">
                      <img src={logo} width="24px" height="24px" alt="" />
                      <Typography
                        sx={{
                          marginLeft: "10px",
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                        fontFamily="Montserrat"
                        color="#ffffff"
                      >
                        $OPX
                      </Typography>
                    </Box>
                    <Typography
                      fontFamily="Montserrat"
                      variant="h6"
                      color="#ffffff"
                    >
                      {amountToBuy ? receivedTokens : "0.0"}
                    </Typography>
                  </Box>
                </Box>
                <Box mt={1} color="#ffffff" fontFamily="Montserrat" display="flex" alignItems="center" justifyContent="space-between">
                  <span>Minimum 0.2 BNB</span>
                  <span>Maximum 3 BNB</span>
                </Box>
                <Box mt={3} textAlign="center" color="#ffffff" fontFamily="Montserrat">
                  $OPX will be airdropped when presale is finished.
                  (the presale has not started yet)
                </Box>
                <Box mt={1} display="flex" alignItems="center" justifyContent="center">
                  {address ? (
                    <StyledButton width="50%" onClick={() => {/*buyHadler()*/}}>
                      Buy
                    </StyledButton>
                  ) : (
                    <StyledButton
                      onClick={async () => {
                        await open();
                      }}
                      width="50%"
                    >
                      Connect wallet
                    </StyledButton>
                  )}
                </Box>
              </Box>
            </div>
            <div className="col-md-6">
              <h1>Secure Your Allocation</h1>
              <ul className="main-features">
                <li>Be a part of the Openex and enjoy the ride!</li>
                <li>Use the first mover advantage and grab your allocation cheap.</li>
                <li>Professional Team - Big tech firm devs and crypto degens.</li>
                <li>Only ~ $40K IMC.</li>
                <li>Soft cap: 125 BNB. Hard cap: 300 BNB</li>
                <li>Platform goes live in September.</li>
                <li>Be a part of the first legit dApp on opBNB.</li>
              </ul>
                    </div>
                  </div>
                </div>
      </section>
    </>
  );
}

export default Media;
